<template>
  <div>
    <base-page
      title="Orders"
      :breadcrumbs="breadcrumbs"
      call-to-action-text="Place an order"
      call-to-action-permission="insurance.orders.index"
      @call-to-action="dialog = true"
    >
      <template #content>
        <order-list 
          @edit="edit"
        />
      </template>
    </base-page>

    <order-form
      v-if="dialog"
      :order="order"
      :dialog="dialog"
      @close="close()"
    />
  </div>
</template>

<script>
export default {
  components: {
    'order-list': () => import('@/components/retail/OrderList.vue'),
    'order-form': () => import('@/components/retail/OrderForm.vue'),
  },

  data () {
    return {
      order: null,
      dialog: false,
      breadcrumbs: [
        { text: 'Orders', disabled: true, },
      ],
    }
  },

  methods: {
    close () {
      this.dialog = false
      this.order = null
    },

    edit (order) {
      this.order = order
      this.dialog = true
    }
  }
} 
</script>